import axios from "axios";

import {
  ExceptionBase,
  UnauthorizedValidationException,
  ForbiddenValidationException,
  OperationFailedException,
  InvalidParameterValidationException,
  NotFoundValidationException,
} from "@/dto/exceptions";
import objectHelper from "@/helpers/objectHelper";

class Interceptor {
  constructor() {
    axios.interceptors.response.use(
      (response) => {
        if (objectHelper.isDefined(response.data)) {
          return response.data;
        }
        return response;
      },
      async (error) => {
        let errorInfo = null;
        if (!!error.response.data && !!error.response.data.error) {
          errorInfo = error.response.data.error;
        }

        switch (error.response.status) {
          case 401:
            throw new UnauthorizedValidationException(errorInfo);
          case 400:
            throw new InvalidParameterValidationException(errorInfo);
          case 403:
            throw new ForbiddenValidationException(errorInfo);
          case 404:
            throw new NotFoundValidationException(errorInfo);
          case 424:
            throw new OperationFailedException(errorInfo);
          default:
            throw new ExceptionBase(errorInfo);
        }
      }
    );
  }
}
export default new Interceptor();
