import objectHelper from "@/helpers/objectHelper";
const _dateProps = ["validityBeginningDate", "validityEndDate"];
export default class UserConsent {
  token = "";
  value;
  userId = 0;
  consentId = 0;
  validityBeginningDate;
  validityEndDate;

  constructor(data) {
    if (data) {
      objectHelper.assignOnlyExisting(this, data);
      objectHelper.convertDatePropertiesToMoment(this, _dateProps);
    }
  }
}
