import { Consent, ConsentCustomContent } from "@/dto";
import Axios from "axios";

export default {
  /**
   * récupère le consentement par token
   * @returns renvoie l'objet ou null.
   */
  async getConsent(token) {
    var response = await Axios.post("api/consent", { token: token });
    if (response) {
      return new Consent(response);
    } else {
      return null;
    }
  },
  /**
   * récupère les data du consentement par id
   * @returns renvoie l'objet ou null.
   */
  async getConsentData(id) {
    var response = await Axios.get("api/consent/" + id);
    if (response) {
      return new ConsentCustomContent(response);
    } else {
      return null;
    }
  },
};
