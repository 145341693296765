import objectHelper from "@/helpers/objectHelper";

const _dateProps = ["creationDate"];
export default class Captcha {
  id;
  value;
  imageString;
  creationDate;

  constructor(data) {
    if (data) {
      objectHelper.assignOnlyExisting(this, data);
      objectHelper.convertDatePropertiesToMoment(this, _dateProps);
    }
  }
}
