<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
//https://stackoverflow.com/questions/69048657/dynamic-layout-in-vue-3-with-vite
import EmptyLayout from "./EmptyLayout";
import { ref, markRaw, watch } from "vue";
import { useRoute } from "vue-router";
import { onMounted } from "@vue/runtime-core";

/** Permet de charger dynamiquement les layouts */
export default {
  name: "AppLayout",
  setup() {
    // mounted
    onMounted(() => {
      console.debug("Component is mounted!");
    });
    let layout = ref();
    const route = useRoute();
    watch(
      () => route.meta?.layout,
      async (metaLayout) => {
        try {
          const component =
            metaLayout && (await import(`@/layouts/${metaLayout}.vue`));
          layout.value = markRaw(component?.default || EmptyLayout);
        } catch (e) {
          layout.value = markRaw(EmptyLayout);
        }
      }
    );
    return { layout };
  },
};
</script>
