<template>
  <div class="consent-validation">
    <h2 class="mt-4">
      Le lien est expiré, veuillez contacter notre support :
      {{ this.EMAIL_SUPPORT }}
    </h2>
  </div>
</template>
<script>
export default {
  name: "TokenExpired",
};
</script>
