import { User } from "@/dto";
import Axios from "axios";

export default {
  /**
   * récupère l'utilisateur par son token
   * @returns renvoie l'objet ou null.
   */
  async getUser(token) {
    var response = await Axios.post("api/user", { token: token });
    if (response) {
      return new User(response);
    } else {
      return null;
    }
  },
};
