import objectHelper from "@/helpers/objectHelper";
import UserConsent from "./userConsent";
export default class User {
  id = 0;
  applicationId = 0;
  personId = 0;
  civility = 0;
  firstname = "";
  lastname = "";
  email = "";
  phoneNumber = "";
  userConsent = [];
  isTokenUpToDate = false;

  constructor(data) {
    if (data) {
      objectHelper.assignOnlyExisting(this, data);

      if (
        objectHelper.isDefined(data.userConsent) &&
        data.userConsent.length > 0
      ) {
        for (const userConsentElement of data.userConsent) {
          this.userConsent.push(new UserConsent(userConsentElement));
        }
      }
    }
  }
}
