import objectHelper from "@/helpers/objectHelper";

export default class ConsentCustomContent {
  id = 0;
  consentId = 0;
  banner = [];
  legalMentions = "";
  consentText = "";
  consentTitle = "";
  constructor(data) {
    if (data) {
      objectHelper.assignOnlyExisting(this, data);
      objectHelper.byteArrayToBase64(data.banner);
    }
  }
}
