<template>
  <div class="consent-validation">
    <h2 class="mt-4">
      Votre réponse a bien été enregistrée, vous pouvez fermer cette fenêtre.
    </h2>
  </div>
</template>
<script>
export default {
  name: "ConsentValidation",
};
</script>
