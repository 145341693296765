export default class ExceptionBase {
  message = null;
  properties = [];
  constructor(data) {
    if (data) {
      this.message = data.Message;
      this.properties = data.Properties;
    }
  }
}
