<template>
  <button
    class="loading"
    :class="buttonClass"
    :disabled="disable"
    :title="buttonTitle"
    v-debounce="execute"
    :debounce-events="['click', 'keydown']"
  >
    <span v-if="!loading" class="text">
      <svg
        v-if="iconName != ''"
        class="svg-inline--fa fa-sign-out-alt fa-w-16"
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="sign-out-alt"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        style="width: 16px; margin-bottom: 3px"
      ></svg>

      {{ text }}
    </span>
    <div
      v-if="loading"
      class="spinner-border spinner-border-sm"
      role="status"
    ></div>
  </button>
</template>

<script>
export default {
  name: "ActionButton",
  props: {
    text: {
      type: String,
      default: "",
    },

    buttonTitle: {
      type: String,
      default: "",
    },

    iconName: {
      type: String,
      default: "",
    },
    action: {
      type: Function,
      default: null,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    noLoading: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
    buttonClass: {
      type: String,
      default: "btn btn-primary",
    },
  },
  data: function () {
    return {
      loading: false,
    };
  },
  methods: {
    execute: async function (val, e) {
      e.preventDefault();
      if (!this.noLoading) {
        this.loading = true;
      }
      if (!this.disable && this.action !== null && this.action !== undefined) {
        await this.action();
      }
      if (!this.noLoading) {
        this.loading = false;
      }
    },
  },
};
</script>
