<template>
  <div class="404">
    <h2 class="mt-4">Erreur 404 - Page non trouvée</h2>
  </div>
</template>
<script>
export default {
  name: "404",
};
</script>
