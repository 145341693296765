import objectHelper from "@/helpers/objectHelper";

export default class Application {
  label = "";
  id = 0;

  constructor(data) {
    if (data) {
      objectHelper.assignOnlyExisting(this, data);
    }
  }
}
