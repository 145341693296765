<template>
  <AppLayout><router-view /></AppLayout>
</template>

<script>
import { onMounted } from "@vue/runtime-core";

export default {
  name: "App",
  setup: () => {
    // mounted
    onMounted(() => {
      console.debug("Component is mounted!");
    });
  },
  methods: {},
  computed: {},
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

<style lang="scss">
/** Couleurs spécifiques à l'application
*/

#app {
  h1 {
    color: $blue-app;
    font-weight: bold;
  }
  h2 {
    color: white;
    font-weight: bold;
  }
  .btn-primary {
    background-color: white;
    border-color: $orange;
    color: $orange;
    &:hover {
      color: white;
      border-color: $orange;
      background-color: $orange;
    }
  }
  .btn-secondary {
    background-color: white;
    border-color: $blue-app;
    color: $blue-app;
    &:hover {
      color: white;
      border-color: $blue-app;
      background-color: $blue-app;
    }
  }
  .radius {
    border-radius: 43px;
  }
  .captcha {
    .btn-primary {
      background-color: $orange;
      border-color: $orange;
    }
  }
  .btn:disabled {
    cursor: not-allowed !important;
  }
  .disabled {
    cursor: not-allowed !important;
  }
}
</style>
