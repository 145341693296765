<template>
  <v-style>
    .captcha { width: {{ captchaWidth }}; } .captcha input { max-width:
    {{ captchaWidth }}; } .captchaImg { width: {{ imageWidth }}; }
  </v-style>
  <div class="captcha" beforeunload="unload">
    <div class="input">
      <img
        :src="captchaGenerated.imageString"
        class="captchaImg"
        alt="captchaImage"
      />
      <div class="btn btn-primary" @click="regenerateCaptcha">
        <svg
          class="svg-inline--fa fa-sync fa-w-16"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="sync"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          data-v-50df3b5a=""
          style="margin: 5px"
        >
          <path
            class=""
            fill="currentColor"
            d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z"
          ></path>
        </svg>
      </div>
    </div>
    <input
      type="text"
      v-model="value"
      v-on:input="updateValue($event.target.value)"
      class="form-control"
      id="captchaTextInput"
    />
  </div>
</template>

<script>
import { Captcha } from "@/dto";
import captchaServices from "@/services/captchaServices";

export default {
  name: "Captcha",
  emits: ["captchaChanged", "captchaValueChanged"],
  props: {
    captchaWidth: {
      type: String,
      default: "300px",
    },
    imageWidth: {
      type: String,
      default: "250px",
    },
  },
  data() {
    return {
      value: "",
      captchaGenerated: new Captcha(),
    };
  },
  mounted() {
    this.$eventBus.on("regenerate", this.regenerateCaptcha);
  },
  beforeUnmount: async function () {
    this.$eventBus.off("regenerate");
  },
  beforeCreate: async function () {
    this.captchaGenerated = await captchaServices.generateCaptcha();
    this.$emit("captchaChanged", this.captchaGenerated);
  },
  methods: {
    regenerateCaptcha: async function () {
      this.captchaGenerated = await captchaServices.regenerateCaptcha(
        this.captchaGenerated.id
      );
      this.value = "";

      this.$emit("captchaChanged", this.captchaGenerated);
    },

    updateValue(value) {
      this.captchaGenerated.value = value;
      this.$emit("captchaValueChanged", this.captchaGenerated.value);
    },
  },
};
</script>

<style scoped lang="scss">
.captcha {
  display: flex;
  flex-direction: column;
}

#captchaTextInput {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

input {
  display: flex;
  flex-direction: row;
  padding-left: 12px;
}
.btn {
  width: 67px;
}
svg {
  font-size: 1.9em;
  color: white;
}
</style>
