import { createApp, h } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import {
  faThumbsUp as thumbsUpRegular,
  faThumbsDown as thumbsDownRegular,
} from "@fortawesome/free-regular-svg-icons";
import VueDebounce from "vue-debounce";

//bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

//fontawesome
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome";

//nos libs
import Interceptor from "@/helpers/interceptors";
import ActionButton from "@/components/ActionButton";
import AppLayout from "@/layouts/AppLayout";
import mitt from "mitt";

import ConfigFileServices from "@/services/config-file-services";

const $eventBus = mitt();

const vueApp = createApp(App).use(store).use(router);
Interceptor;
vueApp.config.globalProperties.$eventBus = $eventBus;
//fontawesome
library.add(faThumbsUp, faThumbsDown, thumbsUpRegular, thumbsDownRegular);
vueApp.component("font-awesome-icon", FontAwesomeIcon);
vueApp.component("font-awesome-layers", FontAwesomeLayers);
vueApp.component("font-awesome-layers-text", FontAwesomeLayersText);

vueApp.component("v-style", {
  render: function () {
    return h(
      "style", // tag name
      {}, // props/attributes
      this.$slots.default() // array of children
    );
  },
});
vueApp.use(VueDebounce, {
  listenTo: "click",
  defaultTime: "300ms",
});

//nos composants
vueApp.component("action-button", ActionButton);
vueApp.component("AppLayout", AppLayout);
vueApp.mount("#app");
(async function () {
  const appSettings = await ConfigFileServices.getAppSettings();
  vueApp.config.globalProperties.EMAIL_SUPPORT = appSettings.emailSupport;
})();
