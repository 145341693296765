//libs
import { createRouter, createWebHashHistory } from "vue-router";

//vues: authentification
import Consent from "../views/Consent.vue";
import ConsentValidation from "../views/ConsentValidation.vue";
import Error404 from "../views/Error404.vue";
import TokenExpired from "../views/TokenExpired.vue";

const routes = [
  {
    path: "/consent",
    name: "Consent",
    meta: { layout: "DefaultLayout", title: "Consentement", crumbs: [] },
    component: Consent,
  },
  {
    path: "/consent-validation",
    name: "ConsentValidation",
    meta: {
      layout: "ConfirmationLayout",
      title: "Consentement validé",
      crumbs: [],
    },
    component: ConsentValidation,
  },
  {
    path: "/token-expired",
    name: "TokenExpired",
    meta: { layout: "ConfirmationLayout", title: "Lien expiré", crumbs: [] },

    component: TokenExpired,
  },
  {
    path: "/:catchAll(.*)*",
    name: "404",
    meta: { layout: "ConfirmationLayout", title: "Erreur 404", crumbs: [] },

    component: Error404,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to) => {
  if (to.meta.title) {
    document.title = to.meta.title + " | Yes2H";
  } else {
    document.title = "Yes2H";
  }
});

export default router;
