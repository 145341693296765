import _ from "lodash";
import moment from "moment";
import { Buffer } from "buffer";
export default {
  isDefined(object) {
    return object !== null && object !== undefined;
  },

  isEmpty(object) {
    return Object.keys(object).length === 0;
  },
  generateUuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  },
  deepCopyFunction(inObject) {
    let outObject, value, key;

    if (typeof inObject !== "object" || inObject === null) {
      return inObject; // Return the value if inObject is not an object
    }

    // Create an array or object to hold the values
    outObject = Array.isArray(inObject) ? [] : {};

    for (key in inObject) {
      value = inObject[key];

      // Recursively (deep) copy for nested objects, including arrays
      outObject[key] = this.deepCopyFunction(value);
    }

    return outObject;
  },
  isAnEmail: function (value) {
    let emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return this.isDefined(value) ? value.match(emailRegex) : false;
  },
  myXOR: function (a, b) {
    return (a || b) && !(a && b);
  },
  isSafeForCsv: function (value) {
    let regex = /^[\w\d]{1}[^\\+\\=\t\r\n\\'\\"\\;\\,]*/;
    var result = this.isDefined(value) ? value.match(regex) : false;

    return result == value;
  },
  /**
   * Affecte les valeurs de l'objet source si les propriétés existent dans l'objet de destination.
   * @param {*} destionationObject
   * @param {*} sourceObject
   * @returns retourne l'objet de destionation.
   */
  assignOnlyExisting(destionationObject, sourceObject) {
    return _.assign(
      destionationObject,
      _.pick(sourceObject, _.keys(destionationObject))
    );
  },
  /**
   * Permet de convertir les propriétés date en instance moment
   * @param {Object} obj objet ayant les propriétés 'createdAt' et 'modifiedAt'
   * @returns returne l'objet
   */
  convertDatePropertiesToMoment(obj, dateProps) {
    for (let p of dateProps) {
      if (obj[p] != undefined) {
        obj[p] = moment.utc(obj[p]).local();
      }
    }

    return obj;
  },

  /**
   * Permet de convertir de tableau de Bytes (C#) en String Base64 (pratique pour les images)
   * @param {*} byteArray
   * @returns retourne une String base64
   */
  byteArrayToBase64(byteArray) {
    return Buffer.from(
      new Uint8Array(byteArray).reduce(function (data1, byte) {
        return data1 + String.fromCharCode(byte);
      }, "")
    ).toString("base64");
  },
};
