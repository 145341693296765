import Axios from "axios";
import { Captcha } from "@/dto";
export default {
  async generateCaptcha() {
    let response = await Axios.get("/api/captcha/generate");
    return new Captcha(response);
  },
  async regenerateCaptcha(oldCaptchaId) {
    let response = await Axios.get("/api/captcha/regenerate/" + oldCaptchaId);
    return new Captcha(response);
  },
};
